import React from 'react';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { BexApiProvider, useBexApi } from '@shared-ui/bex-api-context';
import { ConfigStore } from '@stores/config-store';
import { inject, observer } from 'mobx-react';

type GraphProviderProps = {
  children?: React.ReactNode;
  client: ApolloClient<object>;
};

type ControlPlaneGraphProviderProps = {
  children: React.ReactNode;
  config: ConfigStore;
};

export function GraphProvider({ children, client }: GraphProviderProps): JSX.Element {
  const { context } = useBexApi();

  return (
    <div data-testid="GraphProvider">
      <ApolloProvider client={client}>
        <BexApiProvider context={context}>{children}</BexApiProvider>
      </ApolloProvider>
    </div>
  );
}

export const LocalControlPlaneGraphProvider: React.FC<{ children: React.ReactNode }> = inject('config')(
  observer(({ config, children }: ControlPlaneGraphProviderProps) => {
    const { url } = config.appConfig.controlPlaneGraph;
    const client = new ApolloClient({
      credentials: 'same-origin',
      cache: new InMemoryCache(),
      uri: url,
      name: `advertiser-portal-pwa`,
    });
    return <GraphProvider client={client}>{children}</GraphProvider>;
  }),
);

import React from 'react';

import { useLayoutHelper } from '@hooks/use-layout-helper';
import TopNavBar from '@layout/layout-content/top-nav-bar';
import { ViewContainer } from '@layout/layout-content/view-container';
import { UitkBanner } from 'uitk-react-banner';

interface IProps {
  children: React.ReactNode;
}

export const Layout: React.FC<IProps> = ({ children }: IProps) => {
  const { navOpen, setNavOpen } = useLayoutHelper();
  return (
    <>
      <TopNavBar navOpen={navOpen} setNavOpen={setNavOpen} />
      <noscript>
        <div style={{ borderRadius: 0 }}>
          <UitkBanner theme="error">You need to enable javascript to run this app</UitkBanner>
        </div>
      </noscript>
      <ViewContainer navOpen={navOpen} setNavOpen={setNavOpen}>
        {children}
      </ViewContainer>
    </>
  );
};

import React, { SetStateAction } from 'react';

// import { LocalizedComponentClass, withLocalization, LocalizedText } from '@shared-ui/localization-context';
// import { useAppContext } from 'our-context-plugin/dist/context/';
import { useLayoutHelper } from '@hooks/use-layout-helper';
import { UserStore } from '@stores/user-store';
import { observer, inject } from 'mobx-react';
import Measure, { ContentRect } from 'react-measure';
import { CDN_URL } from 'src/constants';
import { UitkButton } from 'uitk-react-button';
import { GlobalNavigationSiteHeader } from 'uitk-react-global-navigation';
import { UitkHeaderBrandLogo } from 'uitk-react-header-brand-logo';
import { UitkIcon } from 'uitk-react-icons';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';

// import LanguageSelect from './language-select/language-select-content';
// import TopNavSearchTooltip from './search-tooltip/top-nav-search-tooltip-content';

interface IProps {
  navOpen: boolean;
  setNavOpen: React.Dispatch<SetStateAction<boolean>>;
  user?: UserStore;
}

const TopNavBar: React.FC<IProps> = inject('user')(
  observer(({ user, navOpen, setNavOpen }: IProps) => {
    //   const { currentAccount, currentHotels, currentHotel } = useAppContext();
    const { isMobile } = useLayoutHelper();
    // const [tooltipWidth, setTooltipWidth] = useState(400);

    const toggleNav = () => {
      if (navOpen) {
        setNavOpen(false);
      } else {
        setNavOpen(true);
      }
    };

    const handleTooltipResize = (e: ContentRect) => {
      if (e.entry) {
        // setTooltipWidth(e.entry.width);
      }
    };

    //   const idLabel = currentHotel ? currentHotel.hotelId : currentAccount?.accountId;

    return (
      <>
        <GlobalNavigationSiteHeader>
          <UitkSpacing padding={{ inline: 'six' }}>
            <UitkLayoutFlex justifyContent="space-between" alignItems="center" className="top-nav-bar">
              <UitkLayoutFlexItem grow={0} className="top-nav-section">
                <div>
                  <UitkButton
                    data-testid="hamburger"
                    name="hamburger"
                    className="top-nav-hamburger-btn"
                    onClick={toggleNav}
                  >
                    <svg
                      className="nav-icon"
                      width="18"
                      height="12"
                      viewBox="0 0 18 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 12h18v-2H0v2zm0-5h18V5H0v2zm0-7v2h18V0H0z" fill="#FFF" fillRule="evenodd" />
                    </svg>
                  </UitkButton>
                  <UitkHeaderBrandLogo
                    imgSrc={`${CDN_URL}images/ExpediaGroup-Logo.svg`}
                    href="#"
                    altText="Expedia Group Logo"
                  />
                </div>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem>
                <Measure onResize={handleTooltipResize}>
                  {({ measureRef }) => (
                    <div ref={measureRef}>
                      <UitkLayoutFlex space="two" justifyContent="end">
                        <UitkLayoutFlexItem className="top-nav-section">
                          {!isMobile && (
                            <UitkText>{/* <LocalizedText message="topNavBar.idLabel" data={[idLabel]} /> */}</UitkText>
                          )}
                        </UitkLayoutFlexItem>
                        <UitkLayoutFlexItem className="top-nav-section">
                          {/* {currentAccount && currentHotels && <TopNavSearchTooltip tooltipWidth={tooltipWidth} />} */}
                        </UitkLayoutFlexItem>
                        <UitkLayoutFlexItem className="top-nav-section">
                          <UitkLayoutFlex space="four">
                            <UitkLayoutFlexItem>
                              <p>{user.partnerName}</p>
                            </UitkLayoutFlexItem>
                            <UitkLayoutFlexItem>
                              <UitkIcon name="flight" />
                            </UitkLayoutFlexItem>
                            <UitkLayoutFlexItem>
                              <UitkIcon name="account_circle" />
                            </UitkLayoutFlexItem>
                          </UitkLayoutFlex>
                          {/* <LanguageSelect /> */}
                        </UitkLayoutFlexItem>
                      </UitkLayoutFlex>
                    </div>
                  )}
                </Measure>
              </UitkLayoutFlexItem>
            </UitkLayoutFlex>
          </UitkSpacing>
        </GlobalNavigationSiteHeader>
      </>
    );
  }),
);

export default TopNavBar;

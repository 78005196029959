import { gql } from '@apollo/client';

export const CAMPAIGN_MUTATION = gql`
  mutation ($productContext: ProductContext!, $campaignRequestInput: CampaignRequestInput!) {
    publishCampaign(productContext: $productContext, campaignRequestInput: $campaignRequestInput) {
      errorMessage
      successMessageHeading
      successMessageDescription
    }
  }
`;

export const UPDATE_STATUS_MUTATION = gql`
  mutation UpdateCampaignStatusMutation(
    $campaignId: UUID!
    $campaignIsPaused: Boolean
    $campaignName: String
    $adGroupId: UUID
    $adGroupIsPaused: Boolean
    $adGroupName: String
    $adId: UUID
    $adName: String
    $adIsPaused: Boolean
  ) {
    updateCampaignStatus(
      campaignId: $campaignId
      campaignIsPaused: $campaignIsPaused
      campaignName: $campaignName
      adGroupId: $adGroupId
      adGroupIsPaused: $adGroupIsPaused
      adGroupName: $adGroupName
      adId: $adId
      adName: $adName
      adIsPaused: $adIsPaused
    ) {
      ad {
        ad_id
        ad_type
        ad_name
        content_id
        is_paused
      }
      adGroup {
        ad_group_id
        ad_group_name
        budget_id
        campaign_id
        is_paused
      }
      campaign {
        campaign_id
        campaign_name
        is_paused
      }
    }
  }
`;
